export default class ActivityEvents {
  static TYPES = {
    DOCUMENTS_APPROVED: "documentsApproved",
    DOCUMENTS_UPLOADED: "documentsUploaded",
    DOCUMENTS_DELETED: "documentsDeleted",
    DOCUMENT_RECOGNITION_FAILED: "documentRecognitionFailed",
    GPT_AI_FINE_TUNE_FAILED: "gptAiFineTuneFailed",
    GPT_AI_FINE_TUNE_FINISHED: "gptAiFineTuneFinished",
    GPT_AI_FINE_TUNE_STARTED: "gptAiFineTuneStarted",
    NEW_BUSINESS_ADDED: "newBusinessAdded",
    RPA_FAILED: "rpaFailed",
    RPA_FINISHED: "rpaFinished",
    RPA_STARTED: "rpaStarted",
    TASK_ADDED: "taskAdded",
    TASK_MARKED_AS_COMPLETED: "taskMarkedAsCompleted",
    TASK_STATUS_CHANGED: "taskStatusChanged",
    TASK_REMOVED: "taskRemoved",
    TASK_TARGET_USER_CHANGED: "taskTargetUserChanged",
    TRANSACTIONS_APPROVED: "transactionsApproved",
    TRANSACTIONS_EDITED: "transactionsEdited",
    TRANSACTIONS_ASK_CLIENT_FOLLOW_UP: "transactionsAskClientFollowUp",
    TRANSACTIONS_ASK_CLIENT: "transactionsAskClient",
    TRANSACTIONS_AUTO_APPROVED: "transactionsAutoApproved",
    TRANSACTIONS_AUTO_ASK_CLIENT: "transactionsAutoAskClient",
    TRANSACTIONS_AUTO_CATEGORIZED: "transactionsAutoCategorized",
    TRANSACTIONS_AUTO_PAIRED: "transactionsAutoPaired",
    TRANSACTIONS_CATEGORIZED: "transactionsCategorized",
    TRANSACTIONS_NOT_CATEGORIZED: "transactionsNotCategorized",
    TRANSACTIONS_REPLIED_BY_CLIENT: "transactionsRepliedByClient",
    USER_INVITE_FOLLOW_UP: "userInviteFollowUp",
    USER_INVITED: "userInvited",
    USER_REVOKED: "userRevoked"
  };

  static EDITABLE_ACTIVITY_PROPERTIES = [
    { propName: "reason", prevPropName: "prevReason" },
    { propName: "payee", prevPropName: "prevPayee" },
    { propName: "category", prevPropName: "prevCategory" },
    { propName: "item", prevPropName: "prevItem" },
    { propName: "class", prevPropName: "prevClass" },
    { propName: "project", prevPropName: "prevProject" },
    { propName: "location", prevPropName: "prevLocation" },
    { propName: "taxRate", prevPropName: "prevTaxRate" }
  ];
}
