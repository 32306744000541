import Css from "./style.module.scss";

import { TagsInput } from "nlib/ui";
import { getSelectedBusinessCategories } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import classNames from "classnames";

const SelectUncategorizedCategoryNames = ({ className, categories: propsCategories, ...props }) => {
  const { uiTexts } = useSelector(getTextsData);

  const storedCategories = useSelector(getSelectedBusinessCategories);

  const categories = propsCategories || storedCategories;

  const autoCompleteData = useMemo(() => {
    return categories
      .map(({ name }) => ({ value: name, label: name }));
  }, [categories]);

  return (
    <TagsInput
      onlyAutocompleteData
      autoCompleteData={autoCompleteData}
      className={classNames(Css.selectUncategorizedCategoryNames, className)}
      placeholder={uiTexts.enterUncategorizedCategoryNames}
      {...props} />
  );
};

export default React.memo(SelectUncategorizedCategoryNames);
