import ParentCss from "../../style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo } from "react";
import Step from "../Step";
import Utils from "utils/Utils";
import classNames from "classnames";
import useAddNewBusiness from "hooks/useAddNewBusiness";

const Warning = ({ active, serviceToConnect, rpaMode, envVarsError, integrationService, integrationServiceConnected }) => {
  const { messages, errors } = useSelector(getTextsData);

  const { name: businessName } = useSelector(getSelectedBusinessData);

  const integrationServiceData = IntegrationServices.getByValue(integrationService);

  const quickBooksDesktopBusiness = integrationService === IntegrationServices.QUICK_BOOKS_DESKTOP.value;

  const [serviceIntegrationExpiredA, serviceIntegrationExpiredB] = quickBooksDesktopBusiness
    ? messages.quickBooksDesktopExpired : messages.serviceIntegrationExpired;

  if (serviceToConnect && envVarsError) {
    return (
      <div className={ParentCss.error}>
        <Icons.Warning />
        <span className={ParentCss.text}>{errors.integrationIssue}</span>
      </div>
    );
  }

  if (!serviceToConnect && integrationService && !integrationServiceConnected) {
    return (
      <div className={ParentCss.error}>
        <Icons.Warning />
        <span className={ParentCss.text}>
          <span>
            {Utils.replaceTextVars(serviceIntegrationExpiredA, {
              businessName,
              serviceName: integrationServiceData.label
            })}
          </span>
          <br />
          <span>
            {Utils.replaceTextVars(serviceIntegrationExpiredB, {
              businessName,
              serviceName: integrationServiceData.label
            })}
          </span>
        </span>
      </div>
    );
  }

  if (active && rpaMode) {
    return (
      <div className={ParentCss.warning}>
        <Icons.WarningCircle />
        <div className={ParentCss.text}>
          {messages.onboardingQboConnectWarning}
        </div>
      </div>
    );
  }

  return null;
};

const SelectServiceStep = (props) => {
  const {
    index,
    rpaMode,
    currentStep,
    serviceToConnect
  } = props;

  const active = currentStep === index;

  const { uiTexts } = useSelector(getTextsData);

  const {
    extraData: {
      integrationService,
      integrationServiceConnected
    } = {}
  } = useSelector(getSelectedBusinessData);

  const addNewBusiness = useAddNewBusiness();

  const { error: envVarsError } = useMemo(() => {
    return Utils.parseQueryString(window.location.search);
  }, []);

  const handleIntegrationButtonClick = useCallback(({ currentTarget: { dataset } }) => {
    addNewBusiness({ integrationService: dataset.service, rpaMode });
  }, [rpaMode, addNewBusiness]);

  return (
    <Step
      number={index + 1}
      error={(serviceToConnect ? envVarsError : (integrationService && !integrationServiceConnected))}
      active={active}
      success={currentStep > index}
      className={ParentCss.stepFirst}>
      <div className={ParentCss.row}>
        <div className={ParentCss.col}>
          <div className={ParentCss.subtitle}>{uiTexts.connectNewBusiness}</div>
        </div>

        {active
          ? IntegrationServices.getList()
            .filter(({ value }) => value === integrationService)
            .map((service) => {
              return (
                <Button
                  large primary
                  key={service.value}
                  disabled={!!serviceToConnect}
                  loading={serviceToConnect === service.value}
                  data-service={service.value}
                  className={classNames(ParentCss.button, ParentCss.likeButton)}
                  onClick={handleIntegrationButtonClick}>
                  {serviceToConnect === service.value && <Icons.Spinner data-wait />}
                  <span>{service.label}</span>
                </Button>
              );
            })

          : (
            <div className={`${ParentCss.likeButton} ${ParentCss.success}`}>
              <Icons.Check weight="bold" />
              <span>{uiTexts.connected}</span>
            </div>
          )}
      </div>
      <Warning
        active={active}
        serviceToConnect={serviceToConnect}
        rpaMode={rpaMode}
        envVarsError={envVarsError}
        integrationService={integrationService}
        integrationServiceConnected={integrationServiceConnected} />
    </Step>
  );
};

export default React.memo(SelectServiceStep);
