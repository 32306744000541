import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkUsersFetching, getActiveOrganization, getAllUsersData } from "selectors/organizations";
import { getAllBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Buttons from "./lib/Buttons";
import ClientPortalTab from "./lib/ClientPortalTab";
import DigitalBookkeeperTab from "./lib/DigitalBookkeeperTab";
import Form from "nlib/common/Form";
import IntegrationServices from "const/IntegrationServices";
import Modal, { ModalFooter } from "nlib/ui/Modal";
import OcrAiTab from "./lib/OcrAiTab";
import React, { useCallback, useMemo, useState } from "react";
import UncategorizedTab from "./lib/UncategorizedTab";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const FIELDS = {
  advancedDocumentsWorkflow: false,
  recognizeDocumentLineItems: false,
  allowClientPayeeSelection: false,
  allowClientCategorySelection: false,
  allowClientClassSelection: false,
  allowClientLocationSelection: false,
  allowClientProjectSelection: false,
  allowClientTaxRateSelection: false,
  allowApproveWithoutPayee: false,
  allowAutoCreatePayee: false,
  recognizeLineItems: false,
  defaultClientAccountantEmail: "",
  businessDescription: "",
  rpaAutoAskClient: false,
  rpaClientEmail: "",
  rpaAutoApprove: undefined,
  rpaStartFrom: "",
  rpaExcludedAccounts: [],
  rpaTransactionTypes: "",
  rpaFrequency: undefined,
  uncategorizedCategoryNames: []
};

const TABS = {
  DIGITAL_BOOKKEEPER: "digitalBookkeeper",
  CLIENT_PORTAL: "clientPortal",
  UNCATEGORIZED: "uncategorized",
  OCR_AI: "ocrAi"
};

const TABS_LIST = Object.values(TABS);

const EditBusinessSettingsWindow = ({ businessId, onClose, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const fetchingUsers = useSelector(checkUsersFetching);

  const { businessOrganization, ignoreUncategorizedTransactions } = useSelector(getActiveOrganization);

  const businessesData = useSelector(getAllBusinessesData);

  const usersData = useSelector(getAllUsersData);

  const businessData = useMemo(() => {
    return businessesData.find(({ id }) => id === businessId);
  }, [businessId, businessesData]);

  const hasBusinessUsers = useMemo(() => {
    return usersData.some(({ role, businessIds = [] }) => {
      return UserRoles.checkIsBusiness(role) && businessIds.includes(businessId);
    });
  }, [businessId, usersData]);

  const {
    extraData: {
      integrationService,
      uncategorizedRpaEnabled,
      reconciliationRpaEnabled
    } = {},
    settings = {}
  } = businessData;

  const rpaMode = uncategorizedRpaEnabled !== undefined || reconciliationRpaEnabled !== undefined;

  const quickBooksBusiness = integrationService === IntegrationServices.QUICK_BOOKS.value;

  const [activeTab, setActiveTab] = useState(rpaMode ? TABS.DIGITAL_BOOKKEEPER : TABS.UNCATEGORIZED);

  const businessUserEmails = useMemo(() => {
    return usersData
      .filter(({ role, businessIds = [] }) => UserRoles.checkIsBusiness(role) && businessIds.includes(businessId))
      .map(({ email }) => email.toLowerCase());
  }, [businessId, usersData]);

  const initialValues = useMemo(() => {
    const { rpaStartFrom, rpaClientEmail, rpaPaused, rpaFrequency, rpaExcludedAccounts } = settings || {};

    return {
      ...(Utils.getProps(settings, Object.keys(FIELDS), FIELDS)),
      rpaExcludedAccountsToggle: !rpaExcludedAccounts?.length,
      rpaClientEmail: businessUserEmails.includes((rpaClientEmail || "").toLowerCase()) ? rpaClientEmail : "",
      rpaStartFrom: (rpaStartFrom && moment(rpaStartFrom).isValid()) ? Utils.formatApiDate(rpaStartFrom) : "",
      rpaPaused: !!rpaPaused || !rpaFrequency
    };
  }, [businessUserEmails, settings]);

  const IconComponent = useMemo(() => {
    const serviceData = IntegrationServices.getByValue(integrationService);

    return serviceData && serviceData.logo ? () => <img className={Css.logo} src={serviceData.logo} /> : Icons.Briefcase;
  }, [integrationService]);

  const handleCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleFormSubmit = useCallback(({ values }) => {
    const {
      rpaStartFrom,
      rpaClientEmail,
      rpaAutoAskClient,
      rpaTransactionTypes,
      rpaFrequency,
      rpaPaused,
      rpaAutoApprove,
      rpaExcludedAccounts,
      rpaExcludedAccountsToggle,
      uncategorizedCategoryNames,
      ...restValues
    } = values;

    onClose({
      ...restValues,
      ...(quickBooksBusiness && !ignoreUncategorizedTransactions && { uncategorizedCategoryNames }),
      ...(rpaMode ? {
        rpaAutoAskClient: !!(rpaAutoAskClient && rpaClientEmail),
        rpaStartFrom: rpaStartFrom ? Utils.formatNoTimeZoneDate(rpaStartFrom) : "",
        rpaClientEmail,
        rpaAutoApprove,
        rpaExcludedAccounts,
        rpaTransactionTypes,
        rpaFrequency: rpaPaused ? initialValues.rpaFrequency : rpaFrequency,
        rpaPaused
      } : null)
    });
  }, [ignoreUncategorizedTransactions, quickBooksBusiness, initialValues.rpaFrequency, onClose, rpaMode]);

  const handleFormChange = useCallback((name, value, prevValues) => {
    switch (name) {
      case "advancedDocumentsWorkflow":
        return {
          ...prevValues,
          [name]: value,
          recognizeDocumentLineItems: false
        };
      default:
        return { ...prevValues, [name]: value };
    }
  }, []);

  const handleTabClick = useCallback((event) => {
    setActiveTab(event.currentTarget.dataset.tab);
  }, []);

  const ActiveTabComponent = ({
    [TABS.UNCATEGORIZED]: UncategorizedTab,
    [TABS.CLIENT_PORTAL]: ClientPortalTab,
    [TABS.OCR_AI]: OcrAiTab,
    [TABS.DIGITAL_BOOKKEEPER]: DigitalBookkeeperTab
  })[activeTab] || UncategorizedTab;

  return (
    <Modal
      {...restProps}
      title={businessData.name}
      className={Css.editBusinessSettingsWindow}
      dialogClassName={classNames(Css.dialogClassName, rpaMode && Css.rpaMode)}
      contentClassName={Css.contentClassName}
      iconComponent={IconComponent}
      onClose={handleCloseClick}>
      <Form
        disabled={fetchingUsers}
        initialValues={initialValues}
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}>
        {({ onSubmit }) => (
          <>
            <div className={Css.container}>
              <div className={Css.sidebar}>
                {TABS_LIST.map((tab) => {
                  if (!rpaMode && (tab === TABS.DIGITAL_BOOKKEEPER)) {
                    return null;
                  }
                  if (businessOrganization && !hasBusinessUsers && (tab === TABS.CLIENT_PORTAL)) {
                    return null;
                  }

                  return (
                    <div
                      key={tab}
                      data-tab={tab}
                      className={classNames(Css.tab, tab === activeTab && Css.active)}
                      onClick={handleTabClick}>
                      {uiTexts[tab]}
                    </div>
                  );
                })}
              </div>
              <div className={Css.content}>
                <ActiveTabComponent
                  rpaMode={rpaMode}
                  businessId={businessId}
                  integrationService={integrationService}
                  hasBusinessUsers={hasBusinessUsers} />
              </div>
            </div>
            <ModalFooter>
              <Buttons
                disabled={fetchingUsers}
                initialValues={initialValues}
                rpaMode={rpaMode}
                onSubmit={onSubmit}
                onCancel={onClose} />
            </ModalFooter>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default React.memo(EditBusinessSettingsWindow);
