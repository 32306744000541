import Css from "./style.module.scss";

import Constants from "const/Constants";
import React from "react";
import TaskContent from "nlib/common/TaskContent";
import TaskForm from "nlib/common/TaskForm";
import useEnvVars from "hooks/useEnvVars";

const SidePanel = () => {
  const [{ editTask }] = useEnvVars();

  const [editTaskId] = editTask ? editTask.split(".") : [];

  if (!editTaskId) return null;

  if (editTaskId === Constants.NEW_ENTITY_ID) {
    return <TaskForm className={Css.sidePanel} />;
  }

  return <TaskContent className={Css.sidePanel} taskId={editTaskId} />;
};

export default React.memo(SidePanel);
