
import Css from "./style.module.scss";

import { Route, Switch } from "react-router-dom";
import { checkAuthState, checkIsBusinessUser } from "selectors/user";
import { checkGlobalStatsFetching, getGlobalStats, getSelectedBusinessData, getUserBusinessesData } from "selectors/businesses";
import { checkTasksFetching, getAutoTasksRawData } from "selectors/tasks";
import { getAccountsData } from "selectors/accounts";
import { getActiveOrganization } from "selectors/organizations";
import { getRouterData } from "selectors/router";
import { useDispatch, useSelector } from "react-redux";
import AuthHandler from "nlib/common/AuthHandler";
import Businesses from "utils/Businesses";
import Constants from "const/Constants";
import Countries from "const/Countries";
import GlobalTooltip from "nlib/common/GlobalTooltip";
import Header from "nlib/Header";
import LoginRedirect from "nlib/common/LoginRedirect";
import ModalsContainer from "nlib/common/ModalsContainer";
import Navbar from "nlib/Navbar";
import NoBusinessData from "./lib/NoBusinessData";
import Onboarding from "./lib/Onboarding";
import Preloader from "nlib/ui/Preloader";
import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";
import RedirectToDefaultRoute from "nlib/common/RedirectToDefaultRoute";
import SidePanel from "nlib/SidePanel";
import TasksActions from "actions/TasksActions";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";

const SummaryPage = lazy(() => import("nlib/pages/SummaryPage"));

const SettingsPage = lazy(() => import("nlib/pages/SettingsPage"));

const ROUTES = [
  [UiRoutes.AUDIT, lazy(() => import("nlib/pages/AuditPage"))],
  [UiRoutes.TRANSACTIONS, lazy(() => import("nlib/pages/TransactionsPage"))],
  [UiRoutes.DOCUMENTS, lazy(() => import("nlib/pages/DocumentsPage"))],
  [UiRoutes.TASKS, lazy(() => import("nlib/pages/TasksPage"))],
  [UiRoutes.DASHBOARD, lazy(() => import("nlib/pages/DashboardPage"))],
  [UiRoutes.ACTIVITY, lazy(() => import("nlib/pages/ActivityPage"))],
  [UiRoutes.ARCHIVE, lazy(() => import("nlib/pages/VaultPage"))],
  [UiRoutes.CONTACTS, lazy(() => import("nlib/pages/ContactsPage"))],
  [UiRoutes.ACCOUNTS, lazy(() => import("nlib/pages/AccountsPage"))]
];

const NewApp = () => {
  const dispatch = useDispatch();

  const userAuthState = useSelector(checkAuthState);

  const businessesData = useSelector(getUserBusinessesData);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const businessUser = useSelector(checkIsBusinessUser);

  const activeOrganization = useSelector(getActiveOrganization);

  const globalStatsFetching = useSelector(checkGlobalStatsFetching);

  const tasksFetching = useSelector(checkTasksFetching);

  const globalStats = useSelector(getGlobalStats);

  const accountsData = useSelector(getAccountsData);

  const autoTasksData = useSelector(getAutoTasksRawData);

  const routerData = useSelector(getRouterData);

  const [routerSeed, setRouterSeed] = useState(routerData.location.state?.seed);

  const { countryCode } = activeOrganization || {};

  const newBusinessRpaMode = useMemo(() => {
    return !!(activeOrganization && Utils.storageValue(
      `${Constants.LS_KEYS.NEW_BUSINESS_RPA_MODE}_${activeOrganization.id}`,
      undefined,
      true
    ));
  }, [activeOrganization]);

  const checkBusinessIdValid = useCallback((businessId) => {
    return businessId && businessesData.some(({ id }) => id === businessId);
  }, [businessesData]);

  useEffect(() => {
    dispatch(UiActions.togglePreloader(false));
  }, [dispatch]);

  useEffect(() => {
    if (routerData.location.state?.seed) setRouterSeed(routerData.location.state.seed);
  }, [routerData.location.state?.seed]);

  useEffect(() => {
    if (selectedBusinessData.id && globalStats.transactions && !globalStatsFetching && !tasksFetching) {
      dispatch(TasksActions.checkAndUpdateAutoTasksStatus());
    }
  }, [
    businessUser,
    dispatch,
    autoTasksData.length,
    accountsData.length,
    globalStats.transactions,
    globalStats.documents,
    globalStats.audit,
    selectedBusinessData.id,
    globalStatsFetching,
    tasksFetching
  ]);

  return (
    <>
      <div className={Css.app}>
        <Switch>
          <Route path={UiRoutes.LOGIN}>{() => userAuthState ? <RedirectToDefaultRoute /> : <AuthHandler />}</Route>
          <Route path={`${UiRoutes.INTEGRATIONS}/:service`}>
            {({ match: { params: { service } } }) => {
              return userAuthState && !businessUser
                ? (
                  <>
                    <Header onboarding hideIntroButton />
                    <Onboarding service={service} rpaMode={newBusinessRpaMode} />
                  </>
                )
                : <LoginRedirect />;
            }}
          </Route>
          <Route path={`${UiRoutes.SETTINGS}/:section?`}>
            {userAuthState
              ? (
                <>
                  <Header />
                  <Suspense fallback={null}>
                    <SettingsPage />
                  </Suspense>
                </>
              )
              : <LoginRedirect />}
          </Route>
          <Route path="/:businessId?">
            {({ match: { params: { businessId } } }) => {
              if (!userAuthState) return <LoginRedirect />;

              if (businessId && !checkBusinessIdValid(businessId)) {
                return <RedirectToDefaultRoute />;
              }

              if (!businessesData.length) {
                return (
                  <>
                    <Header onboarding hideIntroButton />
                    <NoBusinessData />
                  </>
                );
              }

              if (!businessId && !businessUser) {
                return (
                  <>
                    <Header />
                    <Suspense fallback={<Preloader fixed />}>
                      <SummaryPage />
                    </Suspense>
                  </>
                );
              }

              if (businessId !== selectedBusinessData.id) {
                return <Preloader absolute />;
              }

              const { extraData, settings } = selectedBusinessData;

              const onboarding = countryCode !== Countries.CZ
                && !businessUser && Businesses.checkIsOnboarding(extraData, settings);

              return (
                <div className={Css.outer}>
                  {!onboarding && <Navbar hideIntroButton={onboarding} />}
                  {onboarding
                    ? (
                      <div className={Css.inner}>
                        <Header onboarding={onboarding} />
                        <Onboarding rpaMode={Businesses.checkIsRpaMode(extraData)} />
                      </div>
                    )
                    : (
                      <div className={Css.inner}>
                        <Header />
                        <div className={Css.wrap}>
                          <div className={Css.page}>
                            <Suspense fallback={<Preloader className={Css.preloader} />}>
                              <Switch>
                                {ROUTES.map(([route, PageComponent]) => (
                                  <Route
                                    key={`${route}/${businessId}/${routerSeed}`}
                                    path={`/:businessId${route}/:section?`}
                                    component={PageComponent} />
                                ))}
                                <Route><RedirectToDefaultRoute /></Route>
                              </Switch>
                            </Suspense>
                          </div>
                          <SidePanel />
                        </div>
                      </div>
                    )}
                </div>
              );
            }}
          </Route>
        </Switch>
        <ModalsContainer />
      </div>
      <GlobalTooltip />
    </>
  );
};

export default React.memo(NewApp);
