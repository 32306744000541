import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge, Button } from "nlib/ui";
import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessHasWhoAsk,
  checkSelectedBusinessRpaMode,
  getCurrentQuickBooksRealmId,
  getGlobalStats,
  getSelectedBusinessData
} from "selectors/businesses";
import { getAccountsData } from "selectors/accounts";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AuditSections from "nlib/pages/AuditPage/AuditSections";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo } from "react";
import TransactionsStatuses from "nlib/pages/TransactionsPage/TransactionsStatuses";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import moment from "moment";

const {
  DATETIME_FORMATS: { MONTH_AND_YEAR_TEXT, API_MONTH_DATE, API_DATE },
  AUDIT_SECTIONS,
  AUDIT_SUBSECTIONS_ROUTES
} = Constants;

const {
  TASK_TYPES: { DOCUMENTS, TRANSACTIONS, AUDIT, BANK_FEEDS },
  STATUSES: { TO_RECONCILE, NEED_REACTION, TO_REVIEW }
} = DataConstants;

const TASK_TYPES_TO_PATH = {
  [AUDIT]: `${UiRoutes.AUDIT}/${AUDIT_SECTIONS.TRANSACTIONS}/${AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED}`,
  [DOCUMENTS]: UiRoutes.DOCUMENTS,
  [TRANSACTIONS]: UiRoutes.TRANSACTIONS
};

const TYPE_TO_LANG_ID = {
  [AUDIT]: "cleanUp",
  [DOCUMENTS]: "ocrAi",
  [TRANSACTIONS]: "uncategorized"
};

const TRANSACTION_STATUS_TO_BADGE_STYLE = {
  [TO_RECONCILE]: "alternative",
  [NEED_REACTION]: "warning",
  [TO_REVIEW]: "primary"
};

const AutoTaskContent = ({ type, startDate }) => {
  const history = useHistory();

  const {
    id: selectedBusinessId,
    extraData: { integrationService } = {}
  } = useSelector(getSelectedBusinessData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const { uiTexts } = useSelector(getTextsData);

  const { businessOrganization } = useSelector(getActiveOrganization);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const selectedBusinessHasWhoAsk = useSelector(checkSelectedBusinessHasWhoAsk);

  const businessUser = useSelector(checkIsBusinessUser);

  const globalStats = useSelector(getGlobalStats);

  const accountsData = useSelector(getAccountsData);

  const monthKey = moment(startDate).format(API_MONTH_DATE);

  const handleGoToButtonClick = useCallback(() => {
    if (type === BANK_FEEDS) {
      const { QBO_APP_URL, XERO_APP_URL } = Utils.getProcessEnvVars();

      window.open(quickBooksBusiness ? QBO_APP_URL : XERO_APP_URL, "_blank");

      return;
    }

    if (!TASK_TYPES_TO_PATH[type]) return;

    const fromDate = moment(startDate).startOf("month").format(API_DATE);

    const toDate = moment(startDate).endOf("month").format(API_DATE);

    history.push(`/${selectedBusinessId}${TASK_TYPES_TO_PATH[type]}?fromDate=${fromDate}&toDate=${toDate}`);
  }, [history, quickBooksBusiness, selectedBusinessId, startDate, type]);

  const items = useMemo(() => {
    const stats = globalStats[type] || {};

    switch (type) {
      case TRANSACTIONS:
        return [TO_RECONCILE, NEED_REACTION, TO_REVIEW].map((status) => {
          const count = stats[status]?.byMonth?.[monthKey]?.count;

          const statusData = TransactionsStatuses.getStatusData([
            status,
            businessOrganization,
            rpaMode && !selectedBusinessHasWhoAsk,
            businessUser
          ]);

          return count && {
            count,
            key: status,
            title: uiTexts[statusData.labelLangId],
            counter: (
              <Badge
                counter inverted
                theme={TRANSACTION_STATUS_TO_BADGE_STYLE[status]}>
                {count}
              </Badge>
            )
          };
        }).filter(Boolean);

      case DOCUMENTS:
        return [TO_REVIEW].map((status) => {
          const count = stats[status]?.byMonth?.[monthKey]?.count;

          return count && {
            count,
            key: status,
            title: uiTexts[status],
            counter: <Badge counter inverted theme="negative">{count}</Badge>
          };
        }).filter(Boolean);

      case AUDIT: {
        const [auditTransactionsSection] = AuditSections;

        return auditTransactionsSection.items.map(({ statProp, nameLangId }) => {
          const count = stats[statProp]?.byMonth?.[monthKey]?.count;

          return count && {
            count,
            key: statProp,
            title: uiTexts[nameLangId],
            counter: <Badge counter inverted theme="negative">{count}</Badge>
          };
        }).filter(Boolean);
      }

      case BANK_FEEDS:
        return accountsData
          .filter(({ extraData = {} }) => {
            return extraData.xeroBankFeedDisconnected || extraData.quickBooksBankFeedDisconnected;
          })
          .map(({ id, name }) => ({ key: id, title: name }));

      default:
        return [];
    }
  }, [
    accountsData,
    businessOrganization,
    businessUser,
    globalStats,
    monthKey,
    rpaMode,
    selectedBusinessHasWhoAsk,
    type,
    uiTexts
  ]);

  const totalCount = items.reduce((aggregator, item) => aggregator + item.count, 0);

  return (
    <div className={Css.autoTaskContent}>
      <div className={Css.stats}>
        {!!items.length && (
          <div className={Css.header}>
            {type === BANK_FEEDS ? uiTexts.accounts : moment(startDate).format(MONTH_AND_YEAR_TEXT)}
            {(!!totalCount || !!items.length) && (
              <div className={Css.count}>
                {`${totalCount || items.length} ${({
                  [TRANSACTIONS]: uiTexts.transactions,
                  [AUDIT]: uiTexts.issues,
                  [DOCUMENTS]: uiTexts.documents,
                  [BANK_FEEDS]: uiTexts.lostConnections.toLowerCase()
                })[type]}`}
              </div>
            )}
          </div>
        )}
        <div className={Css.content}>
          {items.length
            ? items.map(({ key, title, counter }) => {
              return (
                <div key={key}className={Css.item}>
                  <div className={Css.title}>{title}</div>
                  <div>{counter}</div>
                </div>
              );
            }) : <div className={Css.empty}>{uiTexts.allDone}</div>}
        </div>
      </div>
      {(!Utils.checkIsTouchDevice() || type !== AUDIT) && (
        <Button
          large block primary
          className={Css.button}
          onClick={handleGoToButtonClick}>
          <span>
            {Utils.replaceTextVars(uiTexts.goToSection, {
              name: type === BANK_FEEDS
                ? IntegrationServices.getByValue(integrationService)?.shortLabel
                : uiTexts[TYPE_TO_LANG_ID[type]]
            })}
          </span>
          <Icons.ArrowUpRight />
        </Button>
      )}
    </div>
  );
};

export default React.memo(AutoTaskContent);
