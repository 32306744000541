import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import Utils from "utils/Utils";
import moment from "moment";

const { DATETIME_FORMATS: { DATE_TEXT, MONTH_AND_YEAR_TEXT } } = Constants;

const { TASK_STATUSES: { COMPLETED }, TASK_TYPES: { CUSTOM, BANK_FEEDS } } = DataConstants;

const useTaskContent = (content) => {
  const { status, type, startDate, dueDate, createdBy, createdAt, targetUser } = content || {};

  const { uiTexts } = useSelector(getTextsData);

  const userData = useSelector(getUserData);

  const { id: userId } = useSelector(getUserData);

  const bankFeedsType = type === BANK_FEEDS;

  const taskCreatedByYou = createdBy && userData.id === createdBy.id;

  const taskForContent = useMemo(() => {
    if (targetUser) {
      if (targetUser.id === userId) return uiTexts.forYou;

      const { fullName: userFullName } = targetUser;

      return Utils.replaceTextVars(uiTexts.forUserName, { userName: userFullName });
    }

    return uiTexts.forAnyAccountant;
  }, [targetUser, uiTexts, userId]);

  const createdByContent = createdBy ? (taskCreatedByYou ? uiTexts.you : createdBy.fullName) : uiTexts.unknown;

  const today = moment.utc(Utils.formatNoTimeZoneDate(new Date(), false));

  return {
    completed: status === COMPLETED,
    dueDateContent: status === COMPLETED ? uiTexts.completed.toUpperCase() : (
      !bankFeedsType && dueDate && moment(dueDate).format(DATE_TEXT)
    ),
    dueDateStatus: (status !== COMPLETED) && !bankFeedsType && dueDate && (
      moment(dueDate).isSameOrBefore(today.startOf("day")) ? "negative"
        : (moment(dueDate).isAfter(today.add(1, "day").endOf("day")) ? null : "warning")
    ),
    taskForContent,
    createdAtContent: !bankFeedsType && moment.duration(moment(createdAt).diff(moment())).humanize(true),
    startDateContent: !bankFeedsType && startDate && (
      type === CUSTOM ? moment(startDate).format(DATE_TEXT) : moment.utc(startDate).format(MONTH_AND_YEAR_TEXT)),
    createdByContent
  };
};

export default useTaskContent;
