import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge, Button, Checkbox, Select } from "nlib/ui";
import { checkIsBusinessUser, getUserData } from "selectors/user";
import { checkTasksFetching } from "selectors/tasks";
import { getActiveUsersData } from "selectors/organizations";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { marked } from "marked";
import { useDispatch, useSelector } from "react-redux";
import Activity from "nlib/common/Activity";
import Attachments from "nlib/common/Attachments";
import AutoTaskContent from "./lib/AutoTaskContent";
import CommentsActions from "actions/CommentsActions";
import Constants from "const/Constants";
import CustomDropArea from "nlib/common/CustomDropArea";
import DataConstants from "const/DataConstants";
import FormLabel from "../FormLabel";
import IntegrationServices from "const/IntegrationServices";
import Preloader from "nlib/ui/Preloader";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import SelectUserAutocomplete from "../TaskForm/lib/FormContent/lib/SelectUserAutocomplete";
import SideBar, { SideBarContent, SideBarFooter, SideBarHeader } from "../SideBar";
import TaskRepeatOptions from "nlib/common/TaskRepeatOptions";
import TasksActions from "actions/TasksActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import purify from "dompurify";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";
import useTaskContent from "hooks/useTaskContent";

const {
  COMMENT_TARGET_TYPES: { TASKS },
  COMMENT_TYPES: { COMMENT },
  TASK_STATUSES,
  TASK_TYPES
} = DataConstants;

const END_TYPES = {
  DATE: "date",
  OCCURRENCES: "occurrences"
};

const ROLES_ALLOWED_TO_ASSIGN_TASKS = [
  UserRoles.ACCOUNTANT_ADMIN.roleId,
  UserRoles.ACCOUNTANT_MANAGER.roleId
];

const TaskContent = ({ className, taskId, mobile }) => {
  const [, setEnvVars] = useEnvVars();

  const textContainerRef = useRef();

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const tasksFetching = useSelector(checkTasksFetching);

  const businessUser = useSelector(checkIsBusinessUser);

  const activeUsersData = useSelector(getActiveUsersData);

  const { extraData: { integrationService } = {} } = useSelector(getSelectedBusinessData);

  const [showAllAttachments, setShowAllAttachments] = useState(false);

  const [showTextExpander, setShowTextExpander] = useState(false);

  const [showAllText, setShowAllText] = useState(false);

  const [activityLoaded, setActivityLoaded] = useState(!taskId);

  const [repeatableData, setRepeatableData] = useState();

  const [content, setContent] = useState(null);

  const { status, type = TASK_TYPES.CUSTOM } = content || {};

  const autoTask = type && (type !== TASK_TYPES.CUSTOM);

  const { shortLabel: serviceName } = IntegrationServices.getByValue(integrationService) || {};

  const {
    completed,
    dueDateContent,
    dueDateStatus,
    createdByContent,
    createdAtContent,
    startDateContent,
    taskForContent
  } = useTaskContent(content);

  const { id: userId, role: userRole } = useSelector(getUserData);

  const canBeManaged = useMemo(() => {
    return !autoTask
      && (userRole === UserRoles.ACCOUNTANT_ADMIN.roleId || (content?.createdBy && content.createdBy.id === userId));
  }, [content?.createdBy, userRole, userId, autoTask]);

  const statusOptions = useMemo(() => {
    return [
      { value: TASK_STATUSES.TO_DO, label: uiTexts[TASK_STATUSES.TO_DO] },
      { value: TASK_STATUSES.IN_PROGRESS, label: uiTexts[TASK_STATUSES.IN_PROGRESS] },
      { value: TASK_STATUSES.ON_HOLD, label: uiTexts[TASK_STATUSES.ON_HOLD] },
      {
        disabled: autoTask,
        value: TASK_STATUSES.COMPLETED,
        label: uiTexts[TASK_STATUSES.COMPLETED]
      }
    ];
  }, [uiTexts, autoTask]);

  const repeatOptionsHasChanges = useMemo(() => {
    const { id: prevId, ...prevData } = content?.repeatableData || {};

    const { id: nextId, ...nextData } = repeatableData || {};

    return !Utils.deepEqual(prevData, nextData);
  }, [content?.repeatableData, repeatableData]);

  const monthYearStartDateText = useMemo(() => {
    return content?.startDate ? moment(content.startDate).format(Constants.DATETIME_FORMATS.MONTH_AND_YEAR_TEXT) : "";
  }, [content?.startDate]);

  const onClose = useCallback(() => {
    setEnvVars({ editTask: null });
  }, [setEnvVars]);

  const fetchData = useCallback(async() => {
    setActivityLoaded(false);

    const [response] = await Promise.all([
      dispatch(TasksActions.fetchTask(taskId)),
      dispatch(CommentsActions.fetchComments(TASKS, taskId, true))
    ]);

    if (response) {
      setContent({
        ...response,
        targetUser: activeUsersData.find(({ id }) => id === response.targetUser)
      });
      setRepeatableData(response?.repeatableData);
    } else {
      onClose();
    }

    setActivityLoaded(true);
  }, [dispatch, taskId, onClose, activeUsersData]);

  const editTask = useCallback(async(payload) => {
    const response = await dispatch(TasksActions.editTask(taskId, payload, true));

    if (!response) return null;

    if (payload.files?.length) {
      const comment = `${messages.filesAttached}: ${payload.files.map(({ name }) => name).join(", ")}`;

      await dispatch(
        CommentsActions.addNewComment(COMMENT, comment, [], false, response.id, TASKS)
      );
    }

    setContent({
      ...response,
      targetUser: activeUsersData.find(({ id }) => id === response.targetUser)
    });

    dispatch(TasksActions.fetchTasksList(false, true));

    return response;
  }, [taskId, messages, dispatch, activeUsersData]);

  const handleBackClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleStatusChange = useCallback(async(newStatus) => {
    if (status === newStatus) return;

    const result = await editTask({ status: newStatus });

    if (result) {
      const message = Utils.replaceTextVars(messages.taskStatusChangedTo, { status: uiTexts[newStatus] });

      dispatch(CommentsActions.addNewComment(COMMENT, message, [], false, taskId, TASKS));
    }
  }, [status, dispatch, editTask, messages, taskId, uiTexts]);

  const handleFilesChange = useCallback(async(files) => {
    setShowAllAttachments(true);

    await editTask({ files });
  }, [editTask]);

  const handleCompletedButtonClick = useCallback(() => {
    handleStatusChange(completed ? TASK_STATUSES.TO_DO : TASK_STATUSES.COMPLETED);
  }, [handleStatusChange, completed]);

  const handleRepeatOptionsChange = useCallback((data) => {
    if (!data.enabled && content?.repeatableData?.enabled) {
      editTask({ repeatableData: data });
    }
    setRepeatableData(data);
  }, [editTask, content?.repeatableData?.enabled]);

  const handleRepeatOptionsCancel = useCallback(() => {
    setRepeatableData(content?.repeatableData);
  }, [content?.repeatableData]);

  const handleRepeatOptionsSave = useCallback(async() => {
    const response = await editTask({ repeatableData });

    if (response) setRepeatableData(response?.repeatableData);
  }, [editTask, repeatableData]);

  const handleTextExpanderClick = useCallback(() => {
    setShowAllText((prev) => !prev);
  }, []);

  const handleTargetUserChange = useCallback((value) => {
    if (value && (content?.targetUser?.id !== value)) {
      editTask({ targetUser: value });
    }
  }, [editTask, content?.targetUser]);

  const handleDeleteTask = useCallback(async() => {
    const result = await showCommonModal({ confirm: true, text: messages.taskDeleteConfirm });

    if (result) {
      setEnvVars({ editTask: null });

      await dispatch(TasksActions.deleteTask(taskId));
    }
  }, [showCommonModal, messages.taskDeleteConfirm, dispatch, taskId, setEnvVars]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [taskId, fetchData, dispatch]);

  useEffect(() => {
    if (textContainerRef.current) {
      setShowTextExpander(textContainerRef.current.clientHeight < textContainerRef.current.scrollHeight);
    }
  }, [content, activityLoaded]);

  if (!content || !activityLoaded) {
    return (
      <SideBar className={classNames(Css.taskContent, className)}>
        <SideBarHeader onCloseClick={handleBackClick}>{uiTexts.task}</SideBarHeader>
        <SideBarContent><Preloader /></SideBarContent>
      </SideBar>
    );
  }

  return (
    <SideBar className={classNames(Css.taskContent, className)}>
      <SideBarHeader onCloseClick={handleBackClick}>{uiTexts.task}</SideBarHeader>
      <SideBarContent>
        <div className={Css.contentWrap}>
          <div className={Css.col}>
            {(!businessUser || autoTask)
              ? (
                <Select
                  disabled={tasksFetching || (autoTask && completed)}
                  className={Css.statusSelect}
                  dropDownClassName={Css.dropDownClassName}
                  value={status}
                  options={statusOptions}
                  onChange={handleStatusChange} />
              )
              : (
                <Button
                  large block outline
                  disabled={tasksFetching}
                  className={Css.completeButton}
                  onClick={handleCompletedButtonClick}>
                  <Checkbox toggle checked={completed} />
                  <span>{completed ? uiTexts.unmarkAsComplete : uiTexts.markAsComplete}</span>
                </Button>
              )}
            {autoTask
              ? (
                <div className={Css.title}>
                  {({
                    [TASK_TYPES.TRANSACTIONS]: Utils.replaceTextVars(
                      uiTexts.categorizeTransactionsForDate,
                      { date: monthYearStartDateText }
                    ),
                    [TASK_TYPES.DOCUMENTS]: Utils.replaceTextVars(
                      uiTexts.reviewUploadedDocumentsForDate,
                      { date: monthYearStartDateText }
                    ),
                    [TASK_TYPES.AUDIT]: Utils.replaceTextVars(
                      uiTexts.resolveCleanUpIssuesForDate,
                      { date: monthYearStartDateText }
                    ),
                    [TASK_TYPES.BANK_FEEDS]: Utils.replaceTextVars(
                      uiTexts.renewServiceBankConnections,
                      { serviceName }
                    )
                  })[content.type] || ""}
                </div>
              )
              : <FormLabel>{uiTexts.fullDescription}</FormLabel>}
            {autoTask
              ? (
                <div className={Css.text}>
                  {({
                    [TASK_TYPES.TRANSACTIONS]: messages.uncategorizedAutoTaskDescription,
                    [TASK_TYPES.DOCUMENTS]: messages.ocrAiAutoTaskDescription,
                    [TASK_TYPES.AUDIT]: messages.cleanUpAutoTaskDescription,
                    [TASK_TYPES.BANK_FEEDS]: messages.bankFeedsAutoTaskDescription
                  })[content.type] || ""}
                </div>
              )
              : (
                <div className={classNames(Css.text, showAllText && Css.showAllText)} ref={textContainerRef}>
                  <div
                    className={Css.markdown}
                    dangerouslySetInnerHTML={{ __html: purify.sanitize(marked(content.text || "")) }} />
                </div>
              )}
            {showTextExpander && (
              <div className={Css.showMoreText} onClick={handleTextExpanderClick}>
                <div>{showAllText ? uiTexts.showLess : uiTexts.showMore}</div>
              </div>
            )}
            {!businessUser && (
              <>
                <FormLabel>{uiTexts.taskFor}</FormLabel>
                <SelectUserAutocomplete
                  className={Css.targetUser}
                  disabled={tasksFetching}
                  value={content.targetUser}
                  allowedRoles={[TASK_TYPES.TRANSACTIONS, TASK_TYPES.DOCUMENTS, TASK_TYPES.AUDIT]
                    .includes(content.type) && ROLES_ALLOWED_TO_ASSIGN_TASKS}
                  onChange={handleTargetUserChange} />
              </>
            )}
            {((autoTask && !businessUser) || (content.type === TASK_TYPES.BANK_FEEDS))
              ? (
                <AutoTaskContent
                  type={content.type}
                  startDate={content.startDate} />
              )
              : (
                <div className={Css.header}>
                  <div className={Css.info}>
                    {!!startDateContent && (
                      <div className={Css.infoItem}>
                        <div className={Css.infoItemTitle}>{uiTexts.startDate}</div>
                        <div className={Css.infoItemValue} title={startDateContent}>
                          <Badge>{startDateContent}</Badge>
                        </div>
                      </div>
                    )}

                    {dueDateContent && (
                      <div className={Css.infoItem}>
                        <div className={Css.infoItemTitle}>{uiTexts.deadline}</div>
                        <div className={Css.infoItemValue}>
                          <Badge theme={dueDateStatus}>{dueDateContent}</Badge>
                        </div>
                      </div>
                    )}

                    {businessUser && (
                      <div className={Css.infoItem}>
                        <div className={Css.infoItemTitle}>{uiTexts.taskFor}</div>
                        <div className={Css.infoItemValue} title={taskForContent}>
                          <Icons.User />
                          <span>{taskForContent}</span>
                        </div>
                      </div>
                    )}

                    <div className={Css.infoItem}>
                      <div className={Css.infoItemTitle}>{uiTexts.created}</div>
                      <div className={Css.infoItemValue}>
                        <span>{createdAtContent}</span>
                      </div>
                    </div>

                    {createdByContent && (
                      <div className={Css.infoItem}>
                        <div className={Css.infoItemTitle}>{uiTexts.createdBy}</div>
                        <div className={Css.infoItemValue}>
                          <span>{createdByContent}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            {!businessUser && !autoTask && (
              <div className={Css.repeatOptions}>
                <TaskRepeatOptions
                  disabled={tasksFetching}
                  startDate={content.startDate}
                  value={repeatableData}
                  onChange={handleRepeatOptionsChange} />
                {repeatableData?.enabled && repeatOptionsHasChanges && (
                  <div className={Css.actions}>
                    <Button
                      outline
                      disabled={tasksFetching}
                      onClick={handleRepeatOptionsCancel}>{uiTexts.cancel}</Button>
                    <Button
                      primary
                      disabled={tasksFetching || (
                        repeatableData.endType === END_TYPES.OCCURRENCES
                          ? !repeatableData.endAfterRepetitions
                          : !repeatableData.endAfterDate
                      )}
                      onClick={handleRepeatOptionsSave}>
                      {uiTexts.save}
                    </Button>
                  </div>
                )}
              </div>
            )}
            <FormLabel>{uiTexts.attachedFiles}</FormLabel>
            {!!content.attachments?.length && (
              <Attachments
                className={Css.attachments}
                showAll={showAllAttachments || undefined}
                attachments={content.attachments} />
            )}
            <CustomDropArea
              disabled={tasksFetching}
              onChange={handleFilesChange} />
          </div>
          <div className={Css.col}>
            <FormLabel>{uiTexts.activity}</FormLabel>
            <Activity
              mobile={mobile}
              dataLoadedInitial
              disabled={tasksFetching}
              className={Css.activity}
              type={TASKS}
              itemId={taskId} />
          </div>
        </div>
      </SideBarContent>
      {canBeManaged && (
        <SideBarFooter>
          <Button
            danger outline large
            icon={Icons.TrashSimple}
            onClick={handleDeleteTask}>{uiTexts.deleteTask}</Button>
        </SideBarFooter>
      )}
    </SideBar>
  );
};

export default React.memo(TaskContent);
