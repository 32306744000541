import Css from "../../style.module.scss";

import { FormElement } from "nlib/common/Form";
import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useMainApi } from "hooks";
import { useSelector } from "react-redux";
import Checkbox from "nlib/ui/Checkbox";
import FormLabel from "nlib/common/FormLabel";
import IntegrationServices from "const/IntegrationServices";
import React from "react";
import SelectDefaultAccountant from "./lib/SelectDefaultAccountant";
import SelectUncategorizedCategoryNames from "nlib/common/SelectUncategorizedCategoryNames";
import UiRoutes from "const/UiRoutes";

const UncategorizedTab = ({ businessId, integrationService, hasBusinessUsers }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const { businessOrganization, ignoreUncategorizedTransactions } = useSelector(getActiveOrganization);

  const quickBooksBusiness = integrationService === IntegrationServices.QUICK_BOOKS.value;

  const [businessData, fetching] = useMainApi({
    fetchCondition: selectedBusinessId !== businessId,
    initialData: {},
    parameters: [`${UiRoutes.BUSINESSES}/${businessId}`]
  });

  const { extraData: { quickBooksClassification } = {} } = businessData;

  return (
    <>
      <div className={Css.block}>
        <FormLabel>{uiTexts.transactions}</FormLabel>
        <div className={Css.row}>
          <FormElement
            toggle
            name="allowApproveWithoutPayee"
            element={Checkbox}>
            <span>{messages.approveWithoutPayeeToggleLabelText}</span>
          </FormElement>
        </div>
      </div>
      {(!businessOrganization || hasBusinessUsers) && (
        <div className={Css.block}>
          <FormLabel>{messages.accountantToAskClient}</FormLabel>
          <div className={Css.row}>
            <FormElement
              businessId={businessId}
              name="defaultClientAccountantEmail"
              element={SelectDefaultAccountant} />
          </div>
        </div>
      )}
      {quickBooksBusiness && !ignoreUncategorizedTransactions && (
        <div className={Css.block}>
          <FormLabel>{messages.settingsUncategorizedLabel}</FormLabel>
          <div className={Css.row} disabled={fetching && (selectedBusinessId !== businessId)}>
            <FormElement
              name="uncategorizedCategoryNames"
              className={Css.tagsInput}
              categories={quickBooksClassification}
              element={SelectUncategorizedCategoryNames} />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(UncategorizedTab);
