import Css from "./style.module.scss";

import { Select } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useMemo } from "react";
import classNames from "classnames";

const { TASK_STATUSES } = DataConstants;

const SelectTaskStatus = ({ className, disabled, value, onChange }) => {
  const { uiTexts } = useSelector(getTextsData);

  const options = useMemo(() => {
    return [
      { value: TASK_STATUSES.TO_DO, label: uiTexts[TASK_STATUSES.TO_DO] },
      { value: TASK_STATUSES.IN_PROGRESS, label: uiTexts[TASK_STATUSES.IN_PROGRESS] },
      { value: TASK_STATUSES.ON_HOLD, label: uiTexts[TASK_STATUSES.ON_HOLD] },
      { value: TASK_STATUSES.COMPLETED, label: uiTexts[TASK_STATUSES.COMPLETED] }
    ];
  }, [uiTexts]);

  return (
    <Select
      className={classNames(Css.selectTaskStatus, className)}
      dropDownClassName={Css.dropDownClassName}
      disabled={disabled}
      value={value}
      options={options}
      onChange={onChange} />
  );
};

export default React.memo(SelectTaskStatus);
