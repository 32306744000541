import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import React, { useEffect, useRef } from "react";
import classNames from "classnames";

const Step = ({ active, success, error, number, className, children }) => {
  const rootRef = useRef();

  useEffect(() => {
    if (active && rootRef.current.scrollIntoView) {
      rootRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [active]);

  return (
    <div
      ref={rootRef}
      data-active={active || undefined}
      data-success={success || undefined}
      data-error={error || undefined}
      className={classNames(Css.step, className, {
        [Css.active]: active,
        [Css.success]: success,
        [Css.error]: error
      })}>
      <div className={Css.number}>
        {success ? <Icons.Check weight="bold" /> : (number || <Icons.Info />)}
      </div>
      <div className={Css.line} />
      <div className={Css.content}>
        {children}
      </div>
    </div>
  );
};

export default React.memo(Step);
