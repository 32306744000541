import Css from "../../style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { FormElement } from "nlib/common/Form";
import { Tooltip } from "nlib/ui";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Checkbox from "nlib/ui/Checkbox";
import Constants from "const/Constants";
import DatePickerInput from "nlib/common/DatePickerInput";
import FormContext from "contexts/FormContext";
import FormLabel from "nlib/common/FormLabel";
import React, { useContext, useMemo } from "react";
import SelectAskPerson from "./lib/SelectAskPerson";
import SelectExcludedAccounts from "./lib/SelectExcludedAccounts";
import SelectRpaFrequency from "./lib/SelectRpaFrequency";
import SelectRpaTransactionTypes from "./lib/SelectRpaTransactionTypes";
import Utils from "utils/Utils";
import moment from "moment";

const { DATETIME_FORMATS } = Constants;

const DigitalBookkeeperTab = ({ disabled, businessId, integrationService }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const quickBooksBusiness = useSelector(getCurrentQuickBooksRealmId);

  const { values: { rpaStartFrom, rpaTransactionTypes } } = useContext(FormContext);

  const rpaStartFromMinDate = useMemo(() => {
    return moment().subtract(1, "years").startOf("year");
  }, []);

  const rpaStartFromInvalid = moment(rpaStartFrom).isBefore(rpaStartFromMinDate);

  return (
    <>
      <div className={Css.block}>
        <div className={Css.row}>
          <div className={Css.col}>
            <FormLabel>{uiTexts.describeYourBusinessForAi}</FormLabel>
            <FormElement
              name="businessDescription"
              placeholder={messages.enterBusinessDescription} />
          </div>
        </div>
        <div className={Css.row}>
          <div className={Css.col}>
            <FormLabel>{uiTexts.frequency}</FormLabel>
            <FormElement
              readOnly={disabled}
              name="rpaFrequency"
              element={SelectRpaFrequency} />
          </div>
        </div>
        <div className={Css.row}>
          <div className={Css.col}>
            <FormLabel>{uiTexts.reviewFrom}</FormLabel>
            <div className={Css.relative}>
              <Tooltip contentClassName={Css.tooltip} opened={rpaStartFromInvalid}>
                <Icons.Warning />
                <span>
                  {Utils.replaceTextVars(messages.rpaMinDateWarning, {
                    date: rpaStartFromMinDate.format(DATETIME_FORMATS.DATE_TEXT)
                  })}
                </span>
              </Tooltip>
              <FormElement
                minDate={rpaStartFromMinDate}
                invalid={!rpaStartFrom || rpaStartFromInvalid}
                name="rpaStartFrom"
                placeholder={uiTexts.selectDate}
                element={DatePickerInput} />
            </div>
          </div>
          <div className={Css.col}>
            <FormLabel>{uiTexts.process}</FormLabel>
            <FormElement
              invalid={!rpaTransactionTypes}
              name="rpaTransactionTypes"
              placeholder={uiTexts.selectValue}
              element={SelectRpaTransactionTypes} />
          </div>
        </div>
        <div className={Css.row}>
          <div className={Css.col}>
            <FormLabel>{uiTexts.excludedAccounts}</FormLabel>
            <FormElement
              readOnly={disabled}
              businessId={businessId}
              integrationService={integrationService}
              name="rpaExcludedAccounts"
              element={SelectExcludedAccounts} />
          </div>
        </div>
        <div className={Css.row}>
          <div className={Css.col}>
            <FormLabel>{uiTexts.askPerson}</FormLabel>
            <FormElement
              readOnly={disabled}
              businessId={businessId}
              name="rpaClientEmail"
              element={SelectAskPerson} />
          </div>
        </div>
        <div className={Css.row}>
          <div className={Css.col}>
            <FormElement toggle name="rpaAutoApprove" element={Checkbox}>
              <span>
                {quickBooksBusiness ? messages.autoMatchTransactionsLabelText : messages.autoReconcileTransactionsLabelText}
              </span>
            </FormElement>
          </div>
        </div>
        <div className={Css.row}>
          <div className={Css.col}>
            <FormElement toggle name="allowAutoCreatePayee" element={Checkbox}>
              <span>{messages.autoCreatePayeeToggleLabelText}</span>
            </FormElement>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(DigitalBookkeeperTab);
