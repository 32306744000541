import ParentCss from "../../style.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { AutoCompleteInput } from "nlib/ui";
import { getAllBusinessesData, getCurrentQuickBooksRealmId, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import Button from "nlib/ui/Button";
import IntegrationServices from "const/IntegrationServices";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import RoboticActions from "actions/RoboticActions";
import Step from "../Step";
import Utils from "utils/Utils";
import classNames from "classnames";

const QBO_ACCOUNTANT_INVITE_GUIDE_URL = "https://youtu.be/vvNpT59TDA4";

const InviteUserStep = ({ index, currentStep }) => {
  const dispatch = useDispatch();

  const { messages, uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const businessesData = useSelector(getAllBusinessesData);

  const {
    id: selectedBusinessId,
    name: businessName,
    extraData: { integrationService } = {}
  } = useSelector(getSelectedBusinessData);

  const quickBooksAccountantClients = useMemo(() => {
    return [...new Set(businessesData.flatMap(({ extraData = {} }) => extraData.quickBooksAccountantClients || []))].sort();
  }, [businessesData]);

  const [fetching, setFetching] = useState(false);

  const [quickBooksAccountantClient, setQuickBooksAccountantClient] = useState({ label: "" });

  const integrationServiceData = IntegrationServices.getByValue(integrationService);

  const [inviteRoboticTextA, inviteRoboticTextB] = messages.onboardingInviteUserStep;

  const [
    onboardingInviteRoboticUserA,
    onboardingInviteRoboticUserB,
    onboardingInviteRoboticUserC,
    onboardingInviteRoboticUserD,
    onboardingInviteRoboticUserE
  ] = useMemo(() => {
    if (!integrationServiceData) return ["", ""];

    let [textA, textB, textC, textD, textE] = messages.onboardingInviteRoboticUser;

    textA = integrationServiceData ? Utils.replaceTextVars(textA, { businessName, service: integrationServiceData.label }) : "";
    textE = integrationServiceData ? Utils.replaceTextVars(textE, { businessName }) : "";

    return [textA, textB, textC, textD, textE];
  }, [messages, businessName, integrationServiceData]);

  const { QBO_RPA_USER, QBO_APP_URL, XERO_RPA_USER, XERO_APP_URL } = Utils.getProcessEnvVars();

  const handleCopyTextClick = useCallback(async({ currentTarget: { textContent } }) => {
    const { clipboard } = window.navigator;

    if (clipboard && clipboard.writeText) {
      await clipboard.writeText(textContent);
      toast.info(uiTexts.copiedToClipboard);
    }
  }, [uiTexts.copiedToClipboard]);

  const handleQuickBooksAccountantClientInputChange = useCallback((value) => {
    setQuickBooksAccountantClient(value?.value ? value : { label: value });
  }, []);

  const handleButtonClick = useCallback(async() => {
    setFetching(true);
    await dispatch(RoboticActions.setQuickBooksAccountantClientName(selectedBusinessId, quickBooksAccountantClient.value));
    await dispatch(BusinessesActions.fetchBusiness(selectedBusinessId, true, true));
    setFetching(false);
  }, [selectedBusinessId, quickBooksAccountantClient, dispatch]);

  return (
    <Step
      number={index + 1}
      className={classNames(ParentCss.stepFourth, Css.step)}
      active={currentStep === index}
      success={currentStep > index}>
      <div className={ParentCss.row}>
        <div className={ParentCss.col}>
          <div className={ParentCss.subtitle}>
            {integrationServiceData
              ? Utils.replaceTextVars(inviteRoboticTextB, { service: integrationServiceData.label })
              : inviteRoboticTextA}
          </div>
          {currentStep === index && (
            <div className={classNames(ParentCss.text, Css.text)}>
              <div>
                {[
                  onboardingInviteRoboticUserA,
                  quickBooksBusiness ? onboardingInviteRoboticUserB : onboardingInviteRoboticUserC
                ].join(" ").split("#").map((string) => {
                  switch (string) {
                    case "email":
                      return (
                        <span key={string} className={ParentCss.copy} onClick={handleCopyTextClick}>
                          <span>{quickBooksBusiness ? QBO_RPA_USER : XERO_RPA_USER}</span>
                          <Icons.Copy />
                        </span>
                      );
                    case "link":
                      return (
                        <a
                          key={string}
                          className={ParentCss.link}
                          href={quickBooksBusiness ? QBO_APP_URL : XERO_APP_URL}
                          target="_blank"
                          rel="noopener noreferrer">
                          <span>
                            {quickBooksBusiness
                              ? IntegrationServices.QUICK_BOOKS.label
                              : IntegrationServices.XERO.label}
                          </span>
                        </a>
                      );
                    default:
                      return string.split("*").map((substr, idx) => {
                        return (
                          <Fragment key={String(idx + 1)}>
                            {idx % "2" ? <b>{substr}</b> : <span>{substr}</span>}
                          </Fragment>
                        );
                      });
                  }
                })}
              </div>
              {quickBooksBusiness && <>
                {quickBooksAccountantClients.length
                  ? <div>
                    <div>
                      {onboardingInviteRoboticUserE.split("*").map((substr, idx) => {
                        return (
                          <Fragment key={String(idx + 1)}>
                            {idx % "2" ? <b>{substr}</b> : <span>{substr}</span>}
                          </Fragment>
                        );
                      })}
                    </div>
                    <AutoCompleteInput
                      invalid={quickBooksAccountantClient.label && !quickBooksAccountantClient.value}
                      valid={quickBooksAccountantClient.value}
                      placeholder={uiTexts.selectClient}
                      value={quickBooksAccountantClient.label}
                      disabled={fetching}
                      className={Css.select}
                      items={quickBooksAccountantClients
                        .sort((valueA, valueB) => valueA.localeCompare(valueB))
                        .map((value) => ({ value, label: value }))}
                      onChange={handleQuickBooksAccountantClientInputChange}
                      onAutoComplete={handleQuickBooksAccountantClientInputChange} />
                  </div>
                  : <div>
                    {onboardingInviteRoboticUserD.split("#").map((string) => {
                      switch (string) {
                        case "link":
                          return (
                            <a
                              key={string}
                              className={ParentCss.link}
                              href={QBO_ACCOUNTANT_INVITE_GUIDE_URL}
                              target="_blank"
                              rel="noopener noreferrer">
                              <span>{uiTexts.video.toLowerCase()}</span>
                            </a>
                          );
                        default:
                          return string.split("*").map((substr, idx) => {
                            return (
                              <Fragment key={String(idx + 1)}>
                                {idx % "2" ? <b>{substr}</b> : <span>{substr}</span>}
                              </Fragment>
                            );
                          });
                      }
                    })}
                  </div>}
              </>}
            </div>
          )}
        </div>
        {currentStep === index && (
          quickBooksAccountantClient.value && !fetching
            ? <Button
              primary large
              className={ParentCss.likeButton}
              onClick={handleButtonClick}>
              {uiTexts.continue}
            </Button>
            : <div className={ParentCss.likeButton}>
              <Icons.Spinner data-wait />
              <span>{uiTexts.pending}</span>
            </div>
        )}
        {currentStep > index && (
          <div className={`${ParentCss.likeButton} ${ParentCss.success}`}>
            <Icons.Check weight="bold" />
            <span>{uiTexts.invited}</span>
          </div>
        )}
      </div>
    </Step>
  );
};

export default React.memo(InviteUserStep);
